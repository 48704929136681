export const longNameMap = new Map([
    ["Aichach-Friedberg", "Aichach-Friedberg (Lkr)"],
    ["Altötting", "Altötting (Lkr)"],
    ["Amberg", "Amberg (Krfr.St)"],
    ["Amberg-S. Lkr.", "Amberg-Sulzbach (Lkr)"],
    ["Ansbach", "Ansbach (Krfr.St)"],
    ["Ansbach Lkr.", "Ansbach (Lkr)"],
    ["Aschaffenburg", "Aschaffenburg (Krfr.St)"],
    ["Aschaffenb. Lkr.", "Aschaffenburg (Lkr)"],
    ["Augsburg", "Augsburg (Krfr.St)"],
    ["Augsburg Lkr.", "Augsburg (Lkr)"],
    ["Bad Kissingen", "Bad Kissingen (Lkr)"],
    ["Bad Tölz-W.", "Bad Tölz-Wolfratshausen (Lkr)"],
    ["Bamberg", "Bamberg (Krfr.St)"],
    ["Bamberg Lkr.", "Bamberg (Lkr)"],
    ["Bayern", "Bayern"],
    ["Bayreuth", "Bayreuth (Krfr.St)"],
    ["Bayreuth Lkr.", "Bayreuth (Lkr)"],
    ["Berchtesg. Land", "Berchtesgadener Land (Lkr)"],
    ["Cham", "Cham (Lkr)"],
    ["Coburg", "Coburg (Krfr.St)"],
    ["Coburg Lkr.", "Coburg (Lkr)"],
    ["Dachau", "Dachau (Lkr)"],
    ["Deggendorf", "Deggendorf (Lkr)"],
    ["Dillingen a.d.D.", "Dillingen a.d.Donau (Lkr)"],
    ["Dingolfing-L.", "Dingolfing-Landau (Lkr)"],
    ["Donau-Ries", "Donau-Ries (Lkr)"],
    ["Ebersberg", "Ebersberg (Lkr)"],
    ["Eichstätt", "Eichstätt (Lkr)"],
    ["Erding", "Erding (Lkr)"],
    ["Erlangen", "Erlangen (Krfr.St)"],
    ["Erlangen-H. Lkr.", "Erlangen-Höchstadt (Lkr)"],
    ["Forchheim", "Forchheim (Lkr)"],
    ["Freising", "Freising (Lkr)"],
    ["Freyung-G.", "Freyung-Grafenau (Lkr)"],
    ["Fürstenfeldb.", "Fürstenfeldbruck (Lkr)"],
    ["Fürth", "Fürth (Krfr.St)"],
    ["Fürth Lkr.", "Fürth (Lkr)"],
    ["Garmisch-P.", "Garmisch-Partenkirchen (Lkr)"],
    ["Günzburg", "Günzburg (Lkr)"],
    ["Haßberge", "Haßberge (Lkr)"],
    ["Hof", "Hof (Krfr.St)"],
    ["Hof Lkr.", "Hof (Lkr)"],
    ["Ingolstadt", "Ingolstadt (Krfr.St)"],
    ["Kaufbeuren", "Kaufbeuren (Krfr.St)"],
    ["Kelheim", "Kelheim (Lkr)"],
    ["Kempten (A.)", "Kempten (Allgäu) (Krfr.St)"],
    ["Kitzingen", "Kitzingen (Lkr)"],
    ["Kronach", "Kronach (Lkr)"],
    ["Kulmbach", "Kulmbach (Lkr)"],
    ["Landsberg a.L.", "Landsberg am Lech (Lkr)"],
    ["Landshut", "Landshut (Krfr.St)"],
    ["Landshut Lkr.", "Landshut (Lkr)"],
    ["Lichtenfels", "Lichtenfels (Lkr)"],
    ["Lindau (B.)", "Lindau (Bodensee) (Lkr)"],
    ["Main-Spessart", "Main-Spessart (Lkr)"],
    ["Memmingen", "Memmingen (Krfr.St)"],
    ["Miesbach", "Miesbach (Lkr)"],
    ["Miltenberg", "Miltenberg (Lkr)"],
    ["Mittelfranken", "Mittelfranken"],
    ["Mühldorf a.Inn", "Mühldorf a.Inn (Lkr)"],
    ["München Lkr.", "München (Lkr)"],
    ["München", "München, Landeshauptstadt"],
    ["Neu-Ulm", "Neu-Ulm (Lkr)"],
    ["Neuburg-S.", "Neuburg-Schrobenhausen (Lkr)"],
    ["Neumarkt i.d.O.", "Neumarkt i.d.OPf. (Lkr)"],
    ["Neustadt a.d.A.-B.W.", "Neustadt a.d.Aisch-Bad Windsheim (Lkr)"],
    ["Neustadt a.d.W.", "Neustadt a.d.Waldnaab (Lkr)"],
    ["Niederbayern", "Niederbayern"],
    ["Nürnberg", "Nürnberg (Krfr.St)"],
    ["Nürnberger L. Lkr.", "Nürnberger Land (Lkr)"],
    ["Oberallgäu", "Oberallgäu (Lkr)"],
    ["Oberbayern", "Oberbayern"],
    ["Oberfranken", "Oberfranken"],
    ["Oberpfalz", "Oberpfalz"],
    ["Ostallgäu", "Ostallgäu (Lkr)"],
    ["Passau", "Passau (Krfr.St)"],
    ["Passau Lkr.", "Passau (Lkr)"],
    ["Pfaffenh. a.d.I.", "Pfaffenhofen a.d.Ilm (Lkr)"],
    ["Regen", "Regen (Lkr)"],
    ["Regensburg", "Regensburg (Krfr.St)"],
    ["Regensb. Lkr.", "Regensburg (Lkr)"],
    ["Rhön-Grabfeld", "Rhön-Grabfeld (Lkr)"],
    ["Rosenheim", "Rosenheim (Krfr.St)"],
    ["Rosenheim Lkr.", "Rosenheim (Lkr)"],
    ["Roth", "Roth (Lkr)"],
    ["Rottal-Inn", "Rottal-Inn (Lkr)"],
    ["Schwabach", "Schwabach (Krfr.St)"],
    ["Schwaben", "Schwaben"],
    ["Schwandorf", "Schwandorf (Lkr)"],
    ["Schweinfurt", "Schweinfurt (Krfr.St)"],
    ["Schweinf. Lkr.", "Schweinfurt (Lkr)"],
    ["Starnberg", "Starnberg (Lkr)"],
    ["Straubing", "Straubing (Krfr.St)"],
    ["Straubing-B. Lkr.", "Straubing-Bogen (Lkr)"],
    ["Tirschenreuth", "Tirschenreuth (Lkr)"],
    ["Traunstein", "Traunstein (Lkr)"],
    ["Unterallgäu", "Unterallgäu (Lkr)"],
    ["Unterfranken", "Unterfranken"],
    ["Weiden i.d.O.", "Weiden i.d.OPf. (Krfr.St)"],
    ["Weilheim-Sch.", "Weilheim-Schongau (Lkr)"],
    ["Weißenburg-G.", "Weißenburg-Gunzenhausen (Lkr)"],
    ["Wunsiedel i.F.", "Wunsiedel i.Fichtelgebirge (Lkr)"],
    ["Würzburg", "Würzburg (Krfr.St)"],
    ["Würzburg Lkr.", "Würzburg (Lkr)"],
]);
